import 'css/jquery3/fancybox/jquery.fancybox-3.5.7.min.css';
import 'hellosign/common/style/hellosign.scss';
import 'hellospa/style.css';
import 'style.scss';

// Some of our global styles are leaking into some DIG
// components. This stylesheet resets a few things.
import 'hellospa/dig/reset.scss';
import 'hellospa/dig/theme/sign.scss';
import 'hellospa/dig/overrides.scss';

window.useSignTheme = true;
